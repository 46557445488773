
import Validation from "@/components/Validation.vue";
import useVuelidate from "@vuelidate/core";
import { email as emailValidation, minLength, required, sameAs } from "@vuelidate/validators";
import FscMultiselect from "@/components/FscMultiselect.vue";
import FscDatepicker from "@/components/FscDatepicker.vue";
import GuestLayout from "@/layout/GuestLayout.vue";
import { computed, defineComponent, getCurrentInstance, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { AxiosError } from "axios";
import SchoolLogo from "@/components/Avatars/SchoolLogo.vue";

export default defineComponent({
  components: {
    GuestLayout,
    FscDatepicker,
    Validation,
    multiselect: FscMultiselect,
    SchoolLogo,
  },
  props: ["overviewFieldsRequired"],
  setup(props) {
    const firstName = ref(null);
    const lastName = ref(null);
    const email = ref<string | null>(null);
    const telephone = ref<string | null>(null);
    const password = ref<string | null>(null);
    const passwordConfirmation = ref<string | null>(null);
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);
    const termsAndConditions = ref<boolean | null>(null);
    const $toasted = getCurrentInstance()?.appContext.config.globalProperties.$toasted;

    const userFoundError = ref("");

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const v$ = useVuelidate(
      {
        firstName: { required, minLength: minLength(2) },
        lastName: { required, minLength: minLength(2) },
        email: { required, email: emailValidation },
        telephone: { required },
        password: { required, minLength: minLength(6) },
        passwordConfirmation: { required, passwordConfirmation: sameAs(password) },
      },
      { firstName, lastName, email, password, passwordConfirmation, telephone }
    );

    const registerAction = async (options: any) => {
      await store.dispatch("submit-registration/create", options);
    };

    const registerLoading = computed(() => store.getters["submit-registration/getIsLoading"]);
    const registerSuccess = computed(() => store.getters["submit-registration/getSuccess"]);
    const registerError = computed<AxiosError>(() => store.getters["submit-registration/getError"]);
    const drivingSchool = computed(() => store.getters["public-info/getDrivingSchool"]);

    const selectedTheoryCourse = computed(() => store.getters["public-info/getSelectedTheoryCourse"]);
    const selectedLicenseClass = computed(() => store.getters["public-info/getSelectedLicenseClass"]);

    const loading = computed<boolean>(() => registerLoading.value);

    const onSubmit = async (): Promise<void> => {
      v$.value.$touch();
      if (termsAndConditions.value === null) {
        termsAndConditions.value = false;
      }
      if (v$.value.$invalid || !drivingSchoolId.value || termsAndConditions.value === false) {
        return;
      }

      const data = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        password: password.value,
        phoneNumber: telephone.value,
        drivingSchoolId: drivingSchoolId.value,
        licenseClassId: selectedLicenseClass.value?.id || null,
        courseId: selectedTheoryCourse.value?.id || null,
      };

      await registerAction({
        resource: props.overviewFieldsRequired ? "/student-pre-registrations/create" : "/students/basic",
        hideSuccessMessage: true,
        data,
      });

      if (registerError.value) {
        if (
          registerError.value.response?.status == 422 &&
          registerError.value.response.data?.globalErrors.length &&
          registerError.value.response.data?.globalErrors[0] == "302 FOUND"
        ) {
          userFoundError.value = registerError.value.response.data?.fieldErrors[0].error;
        }
      }

      if (registerSuccess.value === true) {
        await router.push({ name: props.overviewFieldsRequired ? "FastlanesRegisterDone" : "RegisterDone" });
      }
    };

    const forgotPassword = () => {
      router.push({ name: "ForgotPassword", query: { email: email.value } });
    };

    const drivingSchoolId = computed(() => {
      return route.params?.drivingSchool || 0;
    });

    const drivingSchoolName = computed(() => {
      return drivingSchool.value?.name || route.query?.drivingSchoolName || "";
    });

    return {
      v$,
      firstName,
      lastName,
      email,
      password,
      passwordConfirmation,
      onSubmit,
      loading,
      userFoundError,
      forgotPassword,
      showPassword,
      showConfirmPassword,
      telephone,
      termsAndConditions,
      drivingSchoolId,
      drivingSchoolName,
    };
  },
});
